import React, { useEffect, useState } from "react"
import { Container, Nav, Navbar, NavDropdown, Button } from 'react-bootstrap';
import { Link, Outlet } from "react-router-dom";

const GeneralLayout = () => {

    const [user, setUser] = useState(null)
    
    const logout = () => {
        fetch("/api/user/logout", { 
            method: 'POST'
        })
        .then(response => {
            return response.json()
        })
        .then(data => {
            if (data.status === 200) {
                setUser(null)

            }
        }).catch(error=>console.error(error))
    }

    useEffect(() => {
        

        fetch("/api/user/isLoggedIn")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setUser(data.user)
            })
    }, [])


    const renderLoginOrUser = (user) => {
        if (user) {     
            return (
                <>
                    <Nav className="justify-content-end flex-grow-1 pe-3">
                        <Button variant="success" className="me-2"><Link style={{color: "inherit", textDecoration: "none"}} to="/upload">Upload</Link></Button>
                        <NavDropdown title="Dashboard" id="basic-nav-dropdown">
                            <NavDropdown.Item href="">Profile</NavDropdown.Item>
                            <NavDropdown.Item href="">Support</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="" onClick={logout}>
                                Logout
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </>
            )
        } else {
            return (
                <>
                    <Nav className="justify-content-end flex-grow-1 pe-3">
                        <Nav.Link><Link to="/login" style={{all: "inherit"}}>Login</Link></Nav.Link>
                        <Nav.Link><Link to="/register" style={{all: "inherit"}}>Sign Up</Link></Nav.Link>
                    </Nav>
                </>
            )
        }
    }

    return (    
        <div style={{height: "100vh", display: "flex", flexDirection: "column"}}>
            <Navbar className="bg-body-tertiary p-4">
                <Container>
                    <Navbar.Brand href="/"><Link to="/" style={{all: "inherit"}}>Outline Center</Link></Navbar.Brand>
                        {renderLoginOrUser(user)}
                </Container>
            </Navbar>
            <Outlet />
            <footer className="bg-dark text-white text-center py-5">
                Outline Center
            </footer>
        </div>
    )
}

export default GeneralLayout