import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from 'react-router-dom';
import OutlineModal from "../components/OutlineModal"
import { Spinner, Container, Row, Col, Card, ListGroup, Button, ProgressBar, Badge, Image } from 'react-bootstrap';
import { FaStar, FaRegStar, FaGraduationCap, FaChalkboardTeacher } from 'react-icons/fa';


const Course = () => {

    const [modalShow, setModalShow] = useState(false);
    const [course, setCourse] = useState(null)
    const [outlines, setOutlines] = useState([])
    const [loading, setLoading] = useState(true)
    const location = useLocation()
    const navigate = useNavigate()
    

    useEffect(() => {
        

        fetch("/api/course/find-by-id/" + location.pathname.split("/").at(-1))
            .then(response => {
                return response.json()
            })
            .then(data => {
                setCourse(data.course)
            })
    }, [])


    useEffect(() => {
        
        if (!course) return
        if (course.outlines.length === 0) {
            setLoading(false)
        }
        for (let outline of course.outlines) {
            fetch("/api/outline/get/"+outline)
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    setOutlines(oldArray => [...oldArray, data.outline])
                    if (course.outlines[course.outlines.length - 1] === outline) {
                        setLoading(false)
                    }
                })
        }

    }, [course])


    const renderInfo = (course) => {
        if (!loading) {
            const ratings = [
                { stars: 5, count: 120, width: '75%', color: 'bg-success' },
                { stars: 4, count: 80, width: '60%', color: 'bg-success' },
                { stars: 3, count: 50, width: '40%', color: 'bg-warning' },
                { stars: 2, count: 20, width: '15%', color: 'bg-danger' },
                { stars: 1, count: 10, width: '8%', color: 'bg-danger' },
            ]
            return (


                <Row className="justify-content-center d-flex">
                    <Col md={4} className="mb-3">
                    <Card className="mb-3 shadow-sm border h-100" style={{ border: 'none', borderRadius: '10px' }}>
                            <Card.Body>
                                        {/* Course Details */}
                                        <h3 className="mb-3 text-center">{course.details.title}</h3>
                                        <h5 className="mb-5 text-center">{course.details.professor}</h5>

                                        <h6 className="mb-1 font-bold text-center text-secondary">
                                        {course.details.school} • {course.details.code} • {course.details.department}
                                        </h6>
                            </Card.Body>
                
                        </Card>
                    </Col>
                    <Col md={6} className="mb-3">
                    <Card className="mb-4 shadow-sm border h-100" style={{ border: 'none', borderRadius: '10px' }}>
                            <Card.Body>
                            <Row>
                                <Col md={4} className="text-center">
                                    <h4>Overall Rating</h4>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <FaStar className="text-warning" />
                                        <FaStar className="text-warning" />
                                        <FaStar className="text-warning" />
                                        <FaStar className="text-warning" />
                                        <FaRegStar className="text-warning" />
                                    </div>
                                    <h2>4.0</h2>
                                </Col>
                                <Col md={4} className="text-center">
                                    <h4>Difficulty</h4>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <FaStar className="text-warning" />
                                        <FaStar className="text-warning" />
                                        <FaStar className="text-warning" />
                                        <FaRegStar className="text-warning" />
                                        <FaRegStar className="text-warning" />
                                    </div>
                                    <h2>3.0</h2>
                                </Col>
                                <Col md={4} className="text-center">
                                    <h4>Would Take Again</h4>
                                    <h2>85%</h2>
                                </Col>
                            </Row>

                            <Row>
                            {ratings.map((rating) => (
                                <div className="rating-bar" key={rating.stars}>
                                    <div className="d-flex justify-content-between">
                                        <span>{rating.stars} Stars</span>
                                        <span>{rating.count} Ratings</span>
                                    </div>
                                    <div className="progress">
                                        <div
                                            className={`progress-bar ${rating.color}`}
                                            role="progressbar"
                                            style={{ width: rating.width }}
                                            aria-valuenow={rating.count}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        />
                                    </div>
                                </div>
                            ))}
                            </Row>
                        </Card.Body>
                
                        </Card>
                    </Col>
                </Row>

            )
        }
    }



    const displayOutlines = (outlines) => {
        if (loading) {
            return
        } else if (outlines.length != 0) {
            return (
                <Card className="shadow-sm" style={{ border: 'none', borderRadius: '10px' }}>
                    <Card.Header style={{ backgroundColor: '#c6a883', color: '#fff', borderRadius: '10px 10px 0 0' }}>
                        <h5 className="mb-0">Course Outlines</h5>
                    </Card.Header>
                    <ListGroup variant="flush" className="course-outline-list">
                        {outlines.map((outline) => (
                            <ListGroup.Item key={outline.id} className="d-flex justify-content-between align-items-center">
                                <span>Section {outline.details.section}</span>
                                {outline.details.delivery} {new Date(outline.details.date).getFullYear()}
                                <Button variant="outline-primary" size="sm" onClick={()=>{setModalShow({id: outline.id})}}>
                                    View
                                </Button>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Card>
            )
        } else {
            return (
                <Card className="shadow-sm" style={{ border: 'none', borderRadius: '10px' }}>
                    <Card.Header style={{ backgroundColor: '#c6a883', color: '#fff', borderRadius: '10px 10px 0 0' }}>
                        <h5 className="mb-0">Course Outlines</h5>
                    </Card.Header>
                    <p className="m-3">No outlines yet...</p>                                    
                </Card>
            )
        }
    }

    const displayReviews = () => {
        if (loading) {
            return
        } else {
            return (
                <Card className="shadow-sm" style={{ border: 'none', borderRadius: '10px' }}>
                    <Card.Header style={{ backgroundColor: '#c6a883', color: '#fff', borderRadius: '10px 10px 0 0' }}>
                        <h5 className="mb-0">Reviews</h5>
                    </Card.Header>
                    <p className="m-3">No reviews yet...</p>                                 
                </Card>
            )
        }
    }

    const loadingScreen = () => {
        if (loading) {
            return <div style={{textAlign: "center"}}><Spinner animation="border" variant="secondary" style={{textAlign: "center"}} /></div>
        }
    }

    return (    
        <>
            <Container fluid className="pt-5 pb-5" style={{ backgroundColor: '#f3e8d9', flexGrow: "1"}}>
                {loadingScreen()}
                {renderInfo(course)}
                <Row className="justify-content-center">
                    <Col md={5} className="mb-3">
                        {displayReviews()}
                    </Col>
                    <Col md={3} className="mb-3">
                        {displayOutlines(outlines)}
                    </Col>
                </Row>
            </Container>
            <OutlineModal show={modalShow} onHide={() => setModalShow(false)} />
        </>
    )
}

export default Course