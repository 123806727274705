import React, { useState } from "react"
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Container from "react-bootstrap/esm/Container";
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';

const Login = () => {

    const [alert, setAlert] = useState({ on: false, text: null })

    const navigate = useNavigate()

    const submitLogin = () => {
        const email = document.getElementById("email").value
        const password = document.getElementById("password").value
        console.log(JSON.stringify({
            email: email,
            password: password
        }))

        fetch("/api/user/login", { 
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        })
        .then(response => {
            return response.json()
        })
        .then(data => {
            if (data.status !== 200) {
                setAlert({ on: true, text: data.message })
                setTimeout(() => {
                    setAlert({ on: false, text: data.message })
                }, 3000)

            } else {
                navigate("/")
            }
        }).catch(error=>console.error(error))

    }

    return (    
        <>
            <Container fluid style={{backgroundColor: "#f3e8d9", flexGrow: "1"}}>
                <h3 className="mt-5 text-center">Login</h3>
                <Card style={{ width: '18rem' }} className="me-auto ms-auto mt-3">
                    <Card.Body>
                        <Form>
                            <p>Email address</p>
                            <Form.Control id="email" type="email" className="mb-3"/>

                            <p>Password</p>
                            <Form.Control id="password" type="password" className="mb-3"/>

                            <div className="d-grid gap-2"><Button onClick={submitLogin} variant="success">Sign in</Button></div>
                        </Form>
                    </Card.Body>
                </Card>

                <Card style={{ width: '18rem' }} className="me-auto ms-auto mt-3">
                    <p className="text-center mt-3 fw-bold" style={{ fontSize: "0.9em", textDecoration: "none" }}><Link style={{ textDecoration: "none" }}>Forgot Password?</Link></p>
                    <p className="text-center" style={{ fontSize: "0.9em" }}>New to Outline Center? <Link to="/register" style={{ textDecoration: "none" }}>Register here</Link></p>
                </Card>

                <Alert show={alert.on} variant="danger" style={{ width: '18rem' }} className="me-auto ms-auto mt-3" >
                    <p id="alert-text" style={{ margin: 0 }}>{alert.text}</p>
                </Alert>
            </Container>
        </>
    )
}

export default Login