import React from "react"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Container from "react-bootstrap/esm/Container";
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card";

const CommunityIcon = (props) => {
    const iconStyles = {
        width: '100%',
        height: 'auto',
        maxWidth: '50%',
        filter: 'invert(0.9)'
    }

    return (
        <Col xs={12} md={4} className="text-center my-4">
            <h3 className="text-light">{props.text}</h3>
            <img
                src={props.src}
                alt={props.alt}
                className="img-fluid"
                style={iconStyles}
            />
        </Col>
    )
}

const Home = () => {

    let navigate = useNavigate()
    
    const search = () => {
        const input = document.getElementById("search-area")

        navigate('/courses?code=' + input.value)
    }

    const searchBlockStyle = {
        paddingTop: "5rem", 
        paddingBottom: "10rem", 
        backgroundPosition: "center", 
        backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)), url(home-image-1-dim.png)", 
        backgroundSize: "cover"
    }

    const contentStyle = {
        background: 'rgba(255, 255, 255, 0.8)',
        padding: '60px',
        borderRadius: '10px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    };

    const joinBlockStyle = {
        background: "linear-gradient(135deg, #e0c3a5, #8a6f57 100%)",
        backgroundAttachment: "fixed",
        textAlign: "center",
        margin: 0,
        padding: 0

    }

    const backgroundOverlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))',
    };

    const uniqueBackgroundStyle = {
        position: 'relative',
        overflow: 'hidden',
        minHeight: '70vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#333',
        textAlign: 'center',
    };

    const beforeStyle = {
        content: "''",
        position: 'absolute',
        top: '-50px',
        left: '-50px',
        width: '200px',
        height: '200px',
        background: 'rgba(221, 162, 121, 0.1)', // Light peach
        borderRadius: '50%',
        boxShadow: '0 0 50px rgba(0, 0, 0, 0.1)',
        animation: 'moveCircle 10s infinite alternate',
    }

    const afterStyle = {
        content: "''",
        position: 'absolute',
        bottom: '-50px',
        right: '-50px',
        width: '300px',
        height: '300px',
        background: 'rgba(178, 165, 148, 0.5)', // Darker blue
        borderRadius: '50%',
        boxShadow: '0 0 50px rgba(0, 0, 0, 0.1)',
        animation: 'moveCircle 12s infinite alternate-reverse',
    }

    return (    
        <>
        <Container fluid className="text-center" style={joinBlockStyle}>
            <style>
                {`
                    @keyframes moveCircle {
                        0% {
                            transform: translateY(0) translateX(0);
                        }
                        100% {
                            transform: translateY(100px) translateX(100px);
                        }
                    }
                `}
            </style>
            <div style={uniqueBackgroundStyle}>
                <div style={backgroundOverlayStyle}></div>
                <div style={beforeStyle}></div>
                <div style={afterStyle}></div>
            <Card className="col-md-4 p-5">
            <h1>Outline Center</h1>
                    <p>Enter a course code to search outlines</p>
                    <Form>
                        <InputGroup className="mb-3">
                            <Form.Control id="search-area" placeholder="Course code"/>
                            <Button onClick={search} variant="outline-secondary" type="submit">Search</Button>
                        </InputGroup>
                    </Form>
                        </Card>
            </div>
        </Container>


            <Container fluid className="text-center" style={searchBlockStyle}>
                <div className="col-md-8 mx-auto mt-5 mb-5">
                    <h1 className="text-center mb-4 text-light mb-3">Join the Community!</h1>
                    <Row className="justify-content-center">
                        <CommunityIcon text="1. Create Account" src="create-account-image.png" alt="Create Account Image" />
                        <CommunityIcon text="2. Add Outlines" src="add-outline-image.png" alt="Add Outlines Image" />
                        <CommunityIcon text="3. Leave Reviews" src="leave-review-image.png" alt="Leave Review Image" />
                    </Row>
                    <Button className="btn-light mt-5 btn-lg" onClick={()=>{navigate("/register")}}>Sign Up</Button>
                </div>
        </Container>
        </>
    )
}

export default Home