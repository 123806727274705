import React, { useEffect, useState } from "react"
import { Spinner, Container, Card, Col, Row } from "react-bootstrap"
import { useSearchParams, Link, Navigate } from "react-router-dom";



const Courses = () => {

    const [searchParams] = useSearchParams()
    const [courses, setCourses] = useState(null)
    const [loading, setLoading] = useState(true)



    useEffect(() => {
        

        fetch("/api/course/find-by-code?code=" + searchParams.get("code"))
            .then(response => {
                return response.json()
            })
            .then(data => {
                setCourses(data.courses)
                setLoading(false)
            })
    }, [])

    
    if (searchParams.get("code") === null) {
        return <Navigate to='/'  />
    }


    const renderCourses = (courses) => {
        if (loading) {
            return <div style={{textAlign: "center"}}><Spinner animation="border" variant="secondary" /></div>
        } else if (courses && courses.length !== 0) {
            return (
                courses.map((course, i, {length}) => 
                    <div className={"p-3" + ((i + 1 !== length) ? " border-bottom" : "")}>
                        <Link style={{ textDecoration: "none", color: "inherit"}} to={"/course/" + course._id}>
                            <h5 className="mx-3 mt-3" style={{fontWeight: "bold"}}>{course.details.title} <span style={{float: "right"}}>{course.details.code}</span></h5>
                            <h6 className="mx-3 mt-3"><img src="teacher-icon.png" className="me-1" />{course.details.professor}</h6>
                            <p className="m-3"><img src="school-icon.png" className="me-1" />{course.details.school}</p>
                        </Link>
                    </div>
            )
            )
        } else {
            return <p>No search results found</p>
        }
    }


    return (    
        <>
            <div style={{backgroundColor: "#f3e8d9", flexGrow: "1"}}>
            

                <Container className="pt-5 pb-5">

                    <h3 className="mb-5">search results for course code "{searchParams.get("code")}"</h3>
                    <Col md={7} className="p-3 bg-white rounded">
                    {renderCourses(courses)}

                    </Col>

                </Container>
            </div>
            
        </>
    )
}

export default Courses