import { BrowserRouter, Routes, Route } from "react-router-dom"
import Register from "./pages/Register";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Courses from "./pages/Courses";
import Course from "./pages/Course";
import Error404 from "./pages/Error404";
import Upload from "./pages/Upload"
import GeneralLayout from "./components/GeneralLayout";
import UserLayout from "./components/UserLayout";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route element={<GeneralLayout />}>
              <Route index element={<Home />} />
              <Route path="courses" element={<Courses />} />
              <Route path="course/:id" element={<Course />} />
              <Route path="upload" element={<Upload />} />
            </Route>
            <Route element={<UserLayout />}>
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
            </Route>
            <Route path="*" element={<Error404 />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
