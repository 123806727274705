import React from "react"
import { Link } from "react-router-dom"

const Error404 = () => {

    return (    
        <>
            404- Page does not exist
            <Link to="/">Return Home</Link>
        </>
    )
}

export default Error404