// import React, { useState } from "react"
// import CourseModal from "../components/CourseModal"
// import Container from "react-bootstrap/esm/Container";
// import Button from "react-bootstrap/esm/Button";
// import Form from "react-bootstrap/esm/Form";
// import Col from "react-bootstrap/esm/Col";
// import Row from "react-bootstrap/esm/Row";
// import InputGroup from "react-bootstrap/esm/InputGroup";
// import Card from "react-bootstrap/esm/Card";
// import Alert from 'react-bootstrap/Alert';


// const Course = () => {

//     const [courses, setCourses] = useState(null)
//     const [modalShow, setModalShow] = useState(false);
//     const [alert, setAlert] = useState({ on: false, text: null })

//     const searchCourse = () => {
//         const courseName = document.getElementById("search-course").value

//         fetch("/api/course/find-by-code?code="+courseName)
//         .then(response => {
//             return response.json()
//         })
//         .then(data => {
//             setCourses(data.courses)
//         })
        

//     }

//     const setCourseDiv = (courses) => {
//         if (courses === null) return
//         return (
//             <>
//                 <Form id="parent-course" name="parent-course">
//                 {
//                     courses.map((course, index) => 
//                         <Card style={{ width: '50rem' }} className="mb-3" key={new Date().getTime()}>
//                             <Card.Body>
//                                 <Card.Title>{course.details.title} ({course.details.code})</Card.Title>
//                                 <Card.Text>{course.details.school}- {course.details.department}</Card.Text>
//                                 <Card.Text>{course.details.professor}</Card.Text>
//                                 <Form.Check  id={''+index} type={"radio"} name="course-id" value={course._id}/>
//                             </Card.Body>
//                         </Card>
//                     )
//                 }
//                 </Form>

//                 <p>Course not there? Click below to create</p>
//                 <Button variant="primary" onClick={() => setModalShow(true)}>Create Course</Button> 
//                 <CourseModal show={modalShow} set={setCourses} onHide={() => setModalShow(false)}/>
//             </>
//         )
                
//     }

//     const submitOutline = () => {
//         const outlineFile = document.getElementById("outline-file").files[0]
//         const courseDate = document.getElementById("course-date").value
//         const deliveryType = document.getElementById("delivery-type").value
//         const courseSection = document.getElementById("course-section").value
//         const courseId = document.querySelector('input[name="course-id"]:checked');

        
//         if (!outlineFile || !courseDate || deliveryType === "Choose..." || courseSection.trim() === "" || !courseId.value) {
//             setAlert({ on: true, text: "Please fill in all information" })
//                 setTimeout(() => {
//                     setAlert({ on: false, text: "Please fill in all information" })
//                 }, 3000)
        
//         } else if (courseId.value === "noID") {

//             const sCourse = courses[parseInt(courseId.id)]

//             fetch("/api/course/add", {
//                 method: "POST",
//                 headers: {'Content-Type':'application/json'},
//                 body: JSON.stringify({
//                     school: sCourse.details.school,
//                     department: sCourse.details.department,
//                     title: sCourse.details.title,
//                     code: sCourse.details.code,
//                     professor: sCourse.details.professor
//                 })
//             })
//             .then(response => {
//                 return response.json()
//             })
//             .then(data => {

//                 const fd = new FormData()
//                 fd.append('outline', outlineFile)
//                 fd.append('parent', data.id)
//                 fd.append("section", courseSection)
//                 fd.append('delivery', deliveryType)
//                 fd.append('date', new Date(courseDate))

//                 fetch("/api/outline/upload", {
//                     method: "POST",
//                     body: fd
//                 })



//             })

            
            
//         }

//     }

//     return (    
//         <>


//             <Alert show={alert.on} variant="danger" style={{ width: '18rem', position: "fixed" }} >
//                     <p id="alert-text" style={{ margin: 0 }}>{alert.text}</p>
//             </Alert>

            
//             <div style={{backgroundColor: "rgb(220, 220, 230)"}}>
//                 <Container className="pt-5 pb-5">
//                     <h2>Step 1: Select File</h2>
//                     <Form.Group controlId="formFile" className="mb-3 mt-5" style={{ width: '22rem' }}>
//                         <Form.Control type="file"  id="outline-file" name="outline" />
//                     </Form.Group>
//                 </Container>
//             </div>

//             <div style={{backgroundColor: "rgb(230, 220, 210)"}}>
//                 <Container className="pt-5 pb-5">
//                     <h2>Step 2: Select Course</h2>

//                     <InputGroup className="mb-3 mt-5"  style={{ width: '22rem' }}>
//                         <Form.Control placeholder="Enter course code" id="search-course"/>
//                         <Button variant="outline-secondary" onClick={searchCourse}>Search</Button>
//                     </InputGroup>
//                     {setCourseDiv(courses)}



//                 </Container>
//             </div>

//             <div style={{backgroundColor: "rgb(220, 230, 220)"}}>
//                 <Container className="pt-5 pb-5">
//                     <h2>Step 3: Fill Information</h2>

//                     <Row className="mb-3 mt-5">
//                         <Form.Group as={Col} controlId="formGridCity">
//                         <Form.Label>Course Section</Form.Label>
//                         <Form.Control id="course-section"/>
//                         </Form.Group>

//                         <Form.Group as={Col} controlId="formGridState">
//                         <Form.Label>Delivery</Form.Label>
//                         <Form.Select id="delivery-type" defaultValue="Choose...">
//                             <option>Choose...</option>
//                             <option>In-Person</option>
//                             <option>Online</option>
//                             <option>Hybrid</option>
//                             <option>Self-Study</option>
//                         </Form.Select>
//                         </Form.Group>

//                         <Form.Group as={Col} controlId="formGridZip">
//                         <Form.Label>Course Date</Form.Label>
//                         <br />
//                         <input type="date"  id="course-date"/>
//                         </Form.Group>
//                     </Row>


//                     <Button variant="primary" type="submit" onClick={submitOutline}>
//                         Submit
//                     </Button>



//                 </Container>
                
//             </div>
            
//         </>
//     )
// }

// export default Course

import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card, ProgressBar } from 'react-bootstrap';
import MonthYearPicker from '../components/MonthYearPicker'; // Import the custom date selector

function UploadPage() {
  const [pdfFile, setPdfFile] = useState(null);
  const [course, setCourse] = useState('');
  const [outlineInfo, setOutlineInfo] = useState({
    section: '',
    deliveryType: '',
    courseYearMonth: null,
  });

  const handlePdfChange = (e) => {
    setPdfFile(e.target.files[0]);
  };

  const handleCourseChange = (e) => {
    setCourse(e.target.value);
  };

  const handleOutlineInfoChange = (e) => {
    const { name, value } = e.target;
    setOutlineInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value
    }));
  };

  const handleYearMonthChange = (date) => {
    setOutlineInfo((prevInfo) => ({
      ...prevInfo,
      courseYearMonth: date
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('PDF File:', pdfFile);
    console.log('Selected Course:', course);
    console.log('Outline Information:', outlineInfo);
  };

  const getCompletionPercentage = () => {
    let completedFields = 0;
    if (pdfFile) completedFields++;
    if (course) completedFields++;
    if (outlineInfo.section) completedFields++;
    if (outlineInfo.deliveryType) completedFields++;
    if (outlineInfo.courseYearMonth) completedFields++;

    return (completedFields / 5) * 100;
  };

  return (
    <Container style={{ backgroundColor: '#f5f5dc', padding: '20px', marginTop: '20px', borderRadius: '8px' }}>
      <h2 style={{ textAlign: 'center', color: '#333' }}>Outline Center - Upload Page</h2>
      <Row>
        {/* Main Form Section */}
        <Col md={8}>
          <Form onSubmit={handleSubmit}>
            {/* Section 1: Upload PDF */}
            <Card style={{ marginBottom: '20px', borderColor: '#333' }}>
              <Card.Body>
                <Card.Title style={{ color: '#333' }}>1. Upload the Outline PDF</Card.Title>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Control type="file" accept=".pdf" onChange={handlePdfChange} />
                </Form.Group>
              </Card.Body>
            </Card>

            {/* Section 2: Select Course */}
            <Card style={{ marginBottom: '20px', borderColor: '#333' }}>
              <Card.Body>
                <Card.Title style={{ color: '#333' }}>2. Select the Course</Card.Title>
                <Form.Group controlId="formCourseSelect" className="mb-3">
                  <Form.Control as="select" value={course} onChange={handleCourseChange}>
                    <option value="">Select a course</option>
                    <option value="Course 101">Course 101</option>
                    <option value="Course 102">Course 102</option>
                    <option value="Course 103">Course 103</option>
                  </Form.Control>
                </Form.Group>
              </Card.Body>
            </Card>

            {/* Section 3: Outline Information */}
            <Card style={{ marginBottom: '20px', borderColor: '#333' }}>
              <Card.Body>
                <Card.Title style={{ color: '#333' }}>3. Outline Information</Card.Title>
                <Form.Group controlId="formSection" className="mb-3">
                  <Form.Label>Course Section</Form.Label>
                  <Form.Control type="text" name="section" value={outlineInfo.section} onChange={handleOutlineInfoChange} />
                </Form.Group>

                <Form.Group controlId="formDeliveryType" className="mb-3">
                  <Form.Label>Course Delivery Type</Form.Label>
                  <Form.Control type="text" name="deliveryType" value={outlineInfo.deliveryType} onChange={handleOutlineInfoChange} />
                </Form.Group>

                {/* Custom Month and Year Picker */}
                <MonthYearPicker onChange={handleYearMonthChange} />
              </Card.Body>
            </Card>

            {/* Submit Button */}
            <Row>
              <Col style={{ textAlign: 'center' }}>
                <Button variant="dark" type="submit" style={{ backgroundColor: '#333', color: '#fff' }}>
                  Upload Outline
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>

        {/* Progress Section */}
        <Col md={4}>
          <Card style={{ borderColor: '#333' }}>
            <Card.Body>
              <Card.Title style={{ color: '#333' }}>Progress</Card.Title>
              <ProgressBar now={getCompletionPercentage()} label={`${getCompletionPercentage()}%`} style={{ height: '30px', backgroundColor: '#f5f5dc', color: '#333' }} />
              <ul style={{ marginTop: '20px', color: '#333' }}>
                <li>{pdfFile ? 'PDF Uploaded' : 'PDF Not Uploaded'}</li>
                <li>{course ? 'Course Selected' : 'Course Not Selected'}</li>
                <li>{outlineInfo.section ? 'Section Entered' : 'Section Not Entered'}</li>
                <li>{outlineInfo.deliveryType ? 'Delivery Type Entered' : 'Delivery Type Not Entered'}</li>
                <li>{outlineInfo.courseYearMonth ? 'Date Selected' : 'Date Not Selected'}</li>
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default UploadPage;
