import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Form } from 'react-bootstrap';

function MonthYearPicker({ onChange }) {
  const [startDate, setStartDate] = useState(null);

  const handleDateChange = (date) => {
    setStartDate(date);
    if (onChange) {
      onChange(date);
    }
  };

  return (
    <Form.Group controlId="formMonthYear">
      <Form.Label style={{ color: '#333' }}>Select Month and Year</Form.Label>
      <DatePicker
        selected={startDate}
        onChange={handleDateChange}
        dateFormat="MM/yyyy"
        showMonthYearPicker
        showFullMonthYearPicker
        placeholderText="Select month and year"
        className="form-control"
        style={{ width: '100%' }}
      />
    </Form.Group>
  );
}

export default MonthYearPicker;
