
import Modal from "react-bootstrap/esm/Modal";
import { Document, Page } from 'react-pdf';
const NODE_ENV = process.env.NODE_ENV || 'DEVELOPMENT'    


const CourseModal = (props) => {

    let path
    (NODE_ENV === "DEVELOPMENT") ? path = "http://localhost:5000" : path = "https://outlinecenter.com"

    if (!props.show) return

    return (
        
        <Modal {...props}>
            <embed src={path+"/api/outline/view/"+props.show.id} width={"800px"} height={"800px"}/>
        </Modal>
    )
}

export default CourseModal