import { Container, Nav, Navbar, NavDropdown, Button } from 'react-bootstrap';
import { Link, Outlet } from "react-router-dom";

const UserLayout = () => {

    return (    
        <div style={{height: "100vh", display: "flex", flexDirection: "column"}}>
            <Navbar className="bg-body-tertiary p-3">
                    <Container className="justify-content-center">
                    <Link style={{ textDecoration: "none" }} to="/"><Navbar.Brand className="fs-3">Outline Center</Navbar.Brand></Link>
                        
                    </Container>
                </Navbar>
            <Outlet />
            <footer className="bg-dark text-white text-center py-5">
                Outline Center
            </footer>
        </div>
    )
}

export default UserLayout